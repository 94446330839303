<template>
    <div>
        <div id="map-viewer">
            <div id="map-viewer-content">
                <div id="map-viewer-header">
                    <div id="map-filters">
                        <div id="map-filter-container" v-if="user">
                            <input v-model="filterSub" type="checkbox" id="subscribed">
                            <label for="subscribed"><span>Subscribed</span></label>
                            <input v-model="filterLiked" type="checkbox" id="liked">
                            <label for="liked"><span>Liked</span></label>
                        </div>
                    </div>
                    
                    <div id="map-sort">
                        <select v-model="sortKey">
                            <option value="id"></option>
                            <option value="created">Created</option>
                            <option value="modified">Modified</option>
                            <option value="name">Name</option>
                            <option v-if="user" value="liked">Likes</option>
                            <option v-if="user" value="subscribed">Subscriptions</option>
                        </select>
                        <button @click="sortAsc = !sortAsc">{{ sortAsc ? '↑' : '↓' }}</button>
                    </div>
                </div>
                <loading-spinner v-if="loading"></loading-spinner>
                <div v-else id="map-viewer-maps">
                    <div v-for="map in maps" class="map-viewer-map" v-bind:key="map.id" @click="selectMap(map)">
                        <h2 class="map-viewer-map-name">{{ map.name }}</h2>
                        <p class="map-viewer-map-username" v-if="map.username">{{ map.username }}</p>
                        <img :src="`https://yo.raifuwars.com/maps/${map.id}.png`" alt="Map">
                    </div>
                </div>
            </div>
        </div>
        <MapModal v-if="showMapModal" @close="showMapModal = false" :mapProp="selectedMap" @update="refreshMaps"/>
        <div id="map-viewer-footer">
            <div id="map-page">
                <button id="map-page-previous" @click="pagePrev" :disabled="page == 1">←</button>
                <span id="map-page-number">{{ page }}</span>
                <button id="map-page-next" @click="pageNext" :disabled="maps.length < mapsPerPage">→</button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from './common/LoadingSpinner.vue'
import MapModal from './map/MapModal.vue'

export default {
    name: 'MapViewer',
    components: {
        LoadingSpinner,
        MapModal
    },
    data() {
        return {
            maps: [],
            loading: true,
            selectedMap: null,
            showMapModal: false,
            page: 1,
            mapsPerPage: 12,
            sortKey: 'id',
            sortAsc: false,
            filterSub: false,
            filterLiked: false
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        jwt() {
            return this.$store.state.jwt
        }
    },
    mounted() {
        const loggedIn = this.user;
        const hasJWT = this.jwt;
        if (!loggedIn || (loggedIn && hasJWT)) {
            // immediately fetch maps if not logged in or logged in with JWT
            this.refreshMaps();
        }
    },
    watch: {
        sortKey() {
            this.refreshMaps()
        },
        sortAsc() {
            this.refreshMaps()
        },
        filterSub() {
            this.refreshMaps()
        },
        filterLiked() {
            this.refreshMaps()
        },
        user() {
            // if user logs out, reset filters
            if (!this.user) {
                this.resetFilters();
            }
        },
        jwt(oldJWT, newJWT) {
            // only refresh maps if JWT is added or removed
            if (oldJWT && newJWT) {
                return;
            } else {
                this.resetFilters();
                this.refreshMaps();
            }
        }
    },
    methods: {
        refreshMaps() {
            console.log('refreshing maps');
            let query = `?page=${this.page}&per_page=${this.mapsPerPage}&sort_by=${this.sortKey}&order=${this.sortAsc ? 'ASC' : 'DESC'}`;
            if (this.filterSub) {
                query += '&subscribed=true';
            }
            if (this.filterLiked) {
                query += '&liked=true';
            }
            const jwt = this.jwt;
            const options = this.jwt ? { headers: { 'Authorization': jwt } } : {};
            fetch('https://yo.raifuwars.com/api/map'+query, options)
                .then(response => response.json())
                .then(data => {
                    this.loading = false;
                    this.maps = data;
                    // get username for each map from username cache
                    this.maps.forEach(map => {
                        this.$store.dispatch('cacheUsername', map.userId);
                        setInterval(() => {
                            map.username = this.$store.state.usernameCache[map.userId];
                        }, 100);
                    });

                    // if there's a selected map, update it
                    if (this.selectedMap) {
                        this.selectedMap = this.maps.find(map => map.id == this.selectedMap.id);
                    }
                });
        },
        pageNext() {
            this.page++
            this.refreshMaps()
        },
        pagePrev() {
            this.page--
            this.refreshMaps()
        },
        selectMap(map) {
            this.selectedMap = map
            this.showMapModal = true
        },
        resetFilters() {
            this.filterSub = false
            this.filterLiked = false
        }
    }
}
</script>

<style>
#map-viewer {
    color: white;
    padding: 10px;
}
#map-viewer-maps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
#map-viewer-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #faa439;
    color:white;
    padding: 5px;
    border-radius: 5px;
}
#map-viewer-footer button {
    background-color: #faa439;
    color: white;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    font-size: 1.2em;
    padding: 5px;
    margin: 5px;
}
#map-viewer-footer button:hover {
    background-color: #f5b26a;
}
#map-viewer-footer button:active {
    background-color: #f89117;
}
#map-viewer-footer button:disabled {
    background-color: #faa439;
    color: white;
    cursor: default;
}
#map-viewer-footer button:disabled:hover {
    background-color: #faa439;
}
#map-viewer-footer #map-page-number {
    font-size: 1.2em;
    margin: 5px;
}
#map-viewer-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #faa439;
    padding: 5px;
    border-radius: 5px;
}
#map-filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#map-viewer-header input[type="checkbox"] {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid #faa439;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    background-color: white;
}
#map-viewer-header label {
    margin-right: 10px;
    font-size: 1.2em;
}
#map-viewer-header label span {
    vertical-align: middle;
    line-height: 20px;
}
.map-viewer-map {
    background-color: #faa439;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    margin: 10px;
}
.map-viewer-map:hover {
    background-color: #f89117;
    cursor: pointer;
}
.map-viewer-map img {
    width: 90%;
    border-radius: 10px;
}
.map-viewer-map-name {
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 1.8em;
}
.map-viewer-map-username {
    margin-top: 0;
    margin-bottom: 6px;
}
#map-sort select {
    padding: 5px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    font-size: 1.2em;
}
#map-sort button {
    padding: 5px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    font-size: 1.2em;
    margin-left: 5px;
    cursor: pointer;
    background-color: #faa439;
    border: 2px solid white;
    color: white;
}
</style>