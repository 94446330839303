<template>
    <div id="map-modal">
        <div v-if="map" id="map-modal-content">
            <button id="exit-button" @click="$emit('close')">X</button>
            <div id="map-modal-header">
                <h1>{{ map.name }}</h1>
            </div>
            <div id="map-modal-body">
                <img :src="`https://yo.raifuwars.com/maps/${map.id}.png`" alt="Map image">
                <p>{{ map.description }}</p>
            </div>
            <div id="map-modal-footer" v-if="user">
                <button :class="{ 'button-selected': map.user_subscribed }" @click="subscribeMap()">Subscribe 📋</button>
                <button :class="{ 'button-selected': map.user_liked }" @click="likeMap()">Like 👍</button>
                <button v-if="map.userId === user.userId" @click="deleteMap()">Delete 🗑</button>
            </div>
        </div>
        <DeleteModal v-if="showDeleteModal" @close="showDeleteModal = false" :mapProp="map" @delete="onDelete"/>
    </div>
</template>

<script>
import DeleteModal from './DeleteModal.vue'

export default {
    name: 'MapModal',
    components: {
        DeleteModal
    },
    props: {
        mapProp: Object
    },
    data() {
        return {
            map: this.mapProp,
            showDeleteModal: false
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        jwt() {
            return this.$store.state.jwt
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        subscribeMap() {
            const mapId = this.map.id;
            console.log(`Subscribing to map ${mapId}`)
            const subbed = !this.map.user_subscribed;
            const options = {
                headers: {
                    'Authorization': this.jwt,
                },
                method: (subbed ? 'POST' : 'DELETE'),
            };
            fetch(`https://yo.raifuwars.com/api/map/${mapId}/sub`, options)
                .then((res) => {
                    this.map.user_subscribed = subbed;
                    if (res.ok) this.$emit('update');
                });
        },
        likeMap() {
            const mapId = this.map.id;
            console.log(`Liking map ${mapId}`)
            const like = !this.map.user_liked;
            const options = {
                headers: {
                    'Authorization': this.jwt,
                },
                method: (like ? 'POST' : 'DELETE'),
            };
            fetch(`https://yo.raifuwars.com/api/map/${mapId}/like`, options)
                .then((res) => {
                    this.map.user_liked = like;
                    if (res.ok) this.$emit('update');
                });
        },
        deleteMap() {
            const mapId = this.map.id;
            console.log(`Deleting map ${mapId}`)
            this.showDeleteModal = true;
        },
        onDelete() {
            this.showDeleteModal = false;
            this.close();
            this.$emit('update');
        }
    }
}
</script>

<style>
#map-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#map-modal-content {
    background-color: #faa439;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    height: 650px;
    position: relative;
    color: white;
}
#map-modal-content img {
    width: 100%;
    height: 100%;
}
#map-modal-footer > * {
    margin: 0 10px;
}
button {
    background-color: #faa439;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 1.2em;
}
button:hover {
    background-color: white;
    color: #faa439;
}
.button-selected {
    background-color: white;
    color: #faa439;
}
#exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #faa439;
    color: #fff;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
#exit-button:hover {
    background-color: red;
}
</style>