<template>
  <div id="app">
    <TopBar/>
    <MapViewer/>
  </div>
</template>

<script>
import TopBar from './components/TopBar.vue'
import MapViewer from './components/MapViewer.vue'

export default {
  name: 'App',
  components: {
    TopBar,
    MapViewer
  },
  mounted() {
    // get stored user from local storage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      this.$store.commit('setUser', JSON.parse(storedUser));
    }

    // set interval to refresh jwt ~30 minutes
    setInterval(() => {
      this.$store.dispatch('refreshJWT')
    }, 1000 * 60 * 30);
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  watch: {
    user() {
      this.$store.dispatch('refreshJWT')
    }
  }
}
</script>

<style>
html {
  background-color: #ffe6b4;
  text-align: center;
  font-family: Arial, sans-serif;
  height: 100%;
}

#app {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}
</style>
