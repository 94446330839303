<template>
    <div id="login-modal">
        <div id="login-modal-content">
            <button id="exit-button" @click="$emit('close')">X</button>
            <h1>Yotis Online Login</h1>
            <br>
            <form @submit.prevent="login">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input id="username" type="text" v-model="username" class="form-control">
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input id="password" type="password" v-model="password" class="form-control">
                </div>
                <button type="submit" class="btn btn-primary">Login</button>
            </form>
            <p id="error-message" v-if="error">{{ error }}</p>
            <br>
            <span>Need an account? Register at <a href="https://yotisonline.com">Yotis Online!</a></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginModal',
    data() {
        return {
            username: '',
            password: '',
            error: ''
        }
    },
    computed: {
        yoAPI() {
            return this.$store.getters.yoAPI
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        login() {
            const username = this.username;
            const password = this.password;
            fetch(`${this.yoAPI}session/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password })
            }).then(res => {
                if (res.status === 200) {
                    res.json().then((userData) => {
                        this.$store.commit('setUser', userData);
                    });
                    this.$emit('close');
                    this.error = '';
                } 
                else if (res.status === 401) {
                    this.error = 'Password incorrect or account not confirmed';
                } else if (res.status === 404) {
                    this.error = 'Account not found';
                } else {
                    this.error = 'Error logging in';
                }
            });
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style>
#login-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#login-modal-content {
    background-color: #faa439;
    padding: 20px;
    border-radius: 10px;
    width: 350px;
    position: relative;
}
.form-group {
    margin-bottom: 20px;
}
label {
    display: block;
    margin-bottom: 5px;
}
input[type="text"],
input[type="password"] {
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
button[type="submit"] {
    background-color: #faa439;
    color: #fff;
    border: 2px solid white;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}
button[type="submit"]:hover {
    background-color: #ffe6b4;
}
a {
    color: #fff;
    text-decoration: underline;
}
a:hover {
    color: #fff;
    text-decoration: none;
}
#exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #faa439;
    color: #fff;
    border: 2px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
#exit-button:hover {
    background-color: red;
}
#error-message {
    color: red;
    font-size: 1.2em;
    font-weight: bold;
}
</style>