import { createStore } from 'vuex'

const api = 'https://yotisonline.com/api/'
const storedUser = localStorage.getItem('user')

export default createStore({
  state: {
    user: JSON.parse(storedUser) || null,
    jwt: null,
    usernameCache: {},
  },
  getters: {
    yoAPI: () => api
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    setJWT(state, jwt) {
      state.jwt = jwt
    }
  },
  actions: {
    logout({ state, commit }) {
      fetch(`${api}session/`, {
        method: 'DELETE',
        headers: {
            'Authorization': state.jwt,
        }
      }).then(res => {
        if (res.status === 200) {
          commit('setUser', null)
        } else {
          console.error('Failed to logout')
        }
      });
    },
    refreshJWT({ state, commit }) {
      const user = state.user;
      // set null jwt if no user
      if (!user) {
        console.log('no user')
        commit('setJWT', null)
        return
      }

      // call api to refresh jwt
      fetch(`${api}session/jwt/${user.userId}`, {
        method: 'POST',
        headers: {
          'Authorization': `${user.token}`
        }
      }).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            commit('setJWT', data.jwt)
            console.log('got jwt')
          })
        } else {
          console.error('Failed to get YO JWT')
        }
      })
    },
    cacheUsername({ state }, userId) {
      if (state.usernameCache[userId]) {
        return
      }
      fetch(`${api}account/username/${userId}`, {
        method: 'GET'
      }).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            state.usernameCache[userId] = data.username
          })
        } else {
          console.error('Failed to get username')
        }
      }
      )
    }
  },
  modules: {
  }
})