<template>
    <div id="loading"></div>
</template>

<script>
</script>

<style>
#loading {
    margin: 10px;
    display: inline-block;
    width: 75px;
    height: 75px;
    border: 8px solid rgba(255,255,255,.45);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
</style>