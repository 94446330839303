<template>
    <div id="top-bar">
        <img alt="Raifu Wars logo" src="../assets/rw_logo_256.png">
        <h1>Map Repository</h1>
        <div id="user-button">
            <UserButton/>
        </div>
    </div>
</template>

<script>
import UserButton from './UserButton.vue'

export default {
    name: 'TopBar',
    components: {
        UserButton
    }
}
</script>

<style>
    #top-bar {
        background-color: #faa439;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        padding: 10px;
    }

    #top-bar img {
        height: 50px;
        width: 50px;
        margin-right: 10px;
    }

    #top-bar h1 {
        margin: 0;
    }

    #user-button {
        margin-left: auto;
    }
</style>