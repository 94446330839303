<template>
    <div v-if="user">
        <span id="username-display">{{ user.username }}</span>
        <button class="user-action-button" @click="logout">Logout</button>
    </div>
    <div v-else>
        <button class="user-action-button" @click="login">Login</button>
    </div>
    <LoginModal v-if="showLoginModal" @close="showLoginModal = false"/>
</template>

<script>
import LoginModal from './login/LoginModal.vue'

export default {
    name: 'UserButton',
    components: {
        LoginModal
    },
    data() {
        return {
            showLoginModal: false
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout')
        },
        login() {
            this.showLoginModal = true
        }
    }
}
</script>

<style>
.user-action-button {
    background-color: #faa439;
    border: 2px solid white;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}
#username-display {
    color: white;
    font-size: 1.2em;
    margin-right: 10px;
}
</style>