<template>
    <div id="delete-modal">
        <div id="delete-modal-content">
            <h1>Are you sure you want to delete this map?</h1>
            <div id="delete-modal-buttons">
                <button @click="deleteMap()">Yes</button>
                <button @click="close()">No</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeleteModal',
    props: {
        mapProp: Object
    },
    data() {
        return {
            map: this.mapProp
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        jwt() {
            return this.$store.state.jwt
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        deleteMap() {
            const mapId = this.map.id;
            console.log(`Deleting map ${mapId}`)
            const options = {
                headers: {
                    'Authorization': this.jwt,
                },
                method: 'DELETE',
            };
            fetch(`https://yo.raifuwars.com/api/map/${mapId}`, options)
                .then((res) => {
                    if (res.ok) this.$emit('delete');
                });
        }
    }
}
</script>

<style>
#delete-modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
}
#delete-modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}
</style>